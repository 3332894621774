import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bgImage from './gradientBgWithSymbol.jpg';
import styles from './GradientBgBlock.module.scss';

export default class GradientBgBlock extends Component {
    getBgStyle() {
        if (this.props.isBf) {
            return ' linear-gradient(225deg, #002847 0%, #001129 100%)';
        }

        if (this.props.hasSymbol) {
            return `url("${bgImage}")`;
        }
        if (this.props.isOrange) {
            return 'linear-gradient(225deg, #FFBA00 0%, #FF4D00 100%)';
        }
        return 'linear-gradient(45deg, rgba(58,123,213,1) 0%,rgba(0,186,255,1) 100%)';
    }

    render() {
        return (
            <div className={styles.block} style={{ backgroundImage: this.getBgStyle() }}>
                {this.props.children}
            </div>
        );
    }
}

GradientBgBlock.propTypes = {
    children: PropTypes.any,
    hasSymbol: PropTypes.bool,
    isOrange: PropTypes.bool,
    isBf: PropTypes.bool
};
