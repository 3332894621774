import React from 'react';
import PropTypes from 'prop-types';
import styles from './HomepageHeader.module.scss';
import Container from '../../Base/Container/Container';
import HeaderTitle from './HeaderTitle';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import Heading from '../../Base/Heading/Heading';
import Searchbar from "../../List/Searchbar/Searchbar";
import SlideBar from "../../Base/SlideBar/SlideBar";
import FilterGroup from "../../List/Filters/FilterGroup";
import useQueryUrl from "../../../hooks/useQueryUrl";
import favoriteCollections from "../../../utils/favoriteCollections";
import ListItems from "../../../containers/MockupList/ListItems";
import ListPlaceholders from "../../../containers/MockupList/ListPlaceholders";
import MockupList from "../../List/MockupList/MockupList";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import LivePreviewToolbar from "../../List/LivePreview/LivePreviewToolbar";

const HomePageHeader = (props) => {
    const { mockups } = props;
    const intl = useIntl();
    const { makeQueryUrl } = useQueryUrl();
    const buildFilterUrl = (value) => makeQueryUrl(value, 'category', "mockups");
    const filterOptions = [];
    const half = Math.ceil(mockups.length / 2);
    const firstRow = mockups.slice(0, half);
    const secondRow = mockups.slice(half);

    const {
        screenSM,
        screenXMD,
        screenXLG,
        screenXXL
    } = useWindowDimensions();

    const ratioTop = screenDimensions();
    const ratioBottom = screenXMD ? 100 : 80;

    function screenDimensions() {
        if(screenXXL && !screenXLG) {
            return 55;
        }

        if(screenXLG && !screenXMD) {
            return 48
        }

        if(screenXMD) {
            return 100;
        }

        return 72;
    }

    const prepareFilterItems = (tags) => {
        return tags.filter((tag) => {
            if (tag) {
                if (tag.intlId) {
                    const message = {
                        id: `${tag.intlId}.menuTitle`,
                        defaultMessage: tag.menuTitle
                    };

                    tag.menuTitle = intl.formatMessage(message);
                }
                return tag;
            }
            return null;
        });
    };

    filterOptions.push(prepareFilterItems(favoriteCollections));

    const renderMockupsList = (array, responsive, ratio) => {
        if (array && array.length > 0) {
            return (
                <MockupList isMasonry isNoResponsive={responsive}>
                    <ListItems mockups={array} isMasonry isNoResponsive={responsive} ratio={ratio} hasNotCollectButton/>
                </MockupList>
            );
        }

        return (
            <MockupList>
                <ListPlaceholders count={3} />
            </MockupList>
        );
    };

    const renderMockupsRows = () => {
        if(screenSM) {
            return (
                <div>
                    {renderMockupsList(mockups)}
                </div>
            )
        }

        return (
            <>
                <div className={styles.mockupsFirstRow}>
                    {renderMockupsList(firstRow, true, ratioTop)}
                </div>
                <div className={styles.mockupsSecondRow}>
                    {renderMockupsList(secondRow, true, ratioBottom)}
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.topWrapper}>
                <Container isPaddingDisabled>
                    <div className={styles.row}>
                        <div className={styles.content}>
                            <Heading size="sm" tag="p" className="color-grey">
                                <FormattedMessage defaultMessage="The #1 product mockup generator" />
                            </Heading>

                            <HeaderTitle key={props.intl.locale} />

                            <LivePreviewToolbar hasPredefinedPreviews isClientSide trigger="homepageHeader"/>
                        </div>
                        <div className={styles.mockupsWrapper}>
                            <div className={styles.mockupsEmptyTopRow}>
                                {renderMockupsList()}
                            </div>
                            {renderMockupsRows()}
                            <div className={styles.mockupsEmptyBottomRow}>
                                {renderMockupsList()}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={styles.bottomWrapper}>
                <Container isPaddingDisabled>
                    <h2 className={styles.searchTitle}>
                        <FormattedMessage
                            defaultMessage="Let’s create your first mockup!"
                        />
                    </h2>
                    <div className={styles.searchWrap}>
                        <Searchbar />
                    </div>
                    <div className={styles.filtersWrap}>
                        <SlideBar>
                            <FilterGroup
                                filters={filterOptions}
                                active={[]}
                                filtersUrls={buildFilterUrl}
                                isPrimary
                            />
                        </SlideBar>
                    </div>
                </Container>
            </div>
        </>
    );
};

HomePageHeader.propTypes = {
    mockups: PropTypes.array
};

export default injectIntl(HomePageHeader);
