import React, {useRef} from "react";
import styles from "./LivePreviewToolbar.module.scss";
import LivePreviewUploadButtonCore from "./LivePreviewUploadButtonCore";
import {createClass} from "../../../utils/className";
import btnStyles from "../../Base/Button/Button.module.scss";
import Icon from "../../Base/Icons/Icon";
import {FormattedMessage} from "react-intl";
import Image from "next/image";
import PropTypes from "prop-types";
import Edit from "./Edit.svg";
import Button from "../../Base/Button/Button";
import Remove from "./Remove.svg";
import EditBackground from "./EditBackground.png"
import useLivePreview from "../../../hooks/useLivePreview";
import {removePreview} from "../../../utils/routing/LivePreviewRoutes";
import {useRouter} from "next/router";
import Link from "../../Base/LinkLocalised/LinkLocalised";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const LivePreviewToolbar = (props) => {
    const { isClientSide, hasPredefinedPreviews, trigger } = props;
    const livePreview = useLivePreview();
    const router = useRouter();
    const { canvas, predefinedFileId, file } = livePreview;
    const listRef = useRef();
    const {
        screenSM
    } = useWindowDimensions();

   const executeScroll = () => screenSM && listRef.current.scrollIntoView();


    const handleClearLivePreview = async () => {
        if (router.query.preview) {
            await removePreview(router);
        }

        livePreview.clear();
    };

    const renderPredefinedPreview = () => {
        const predefinedPreviews = [
            {
                name: "Logo",
                hasBorder: true
            },
            {
                name: "Photo"
            },
            {
                name: "Art"
            }
        ];

        return predefinedPreviews.map(preview => {
            return (
                <LivePreviewUploadButtonCore
                    className={`${styles.predefinedButton} ${preview.hasBorder ? styles.predefinedButtonBorder : null}`}
                    isPredefined
                    value={preview.name}
                    key={preview.name}
                    trigger={trigger}
                    scrollTo={executeScroll}
                >
                    <Image src={`/livePreview/thumbs/${preview.name}.jpg`} alt={`Try live preview - ${preview.name}`} layout='fill' />
                </LivePreviewUploadButtonCore>
            );
        });
    };

    const renderPredefinedPreviews = () => {
        if(hasPredefinedPreviews) {
            if(canvas && !predefinedFileId || file) {
                return null
            }

            return (
                <>
                    <div>
                        {renderPredefinedPreview()}
                    </div>
                    <span className={styles.separator}>
                        <FormattedMessage defaultMessage="or" />
                    </span>
                </>
            )
        }

        return null;
    };

    const renderUploadButton = () => {
        return (
            <LivePreviewUploadButtonCore
                className={createClass(
                    btnStyles.btn,
                    styles.livePreviewUploadButton
                )}
                isClientSide={isClientSide}
                trigger={trigger}
                scrollTo={executeScroll}
            >
                <Icon name="Image" color="white" />
                <span>
                    <FormattedMessage defaultMessage="Upload your image" />
                </span>
            </LivePreviewUploadButtonCore>
        );
    };

    const renderEditButtons = () => {
        return (
            <>
                <LivePreviewUploadButtonCore
                    className={createClass(btnStyles.btn, styles.livePreviewUploadButtonCompact)}
                    isClientSide={isClientSide}
                    trigger={trigger}
                    scrollTo={executeScroll}
                >
                    <div
                        className={styles.uploadButtonBackground}
                        style={{ backgroundImage: `url(${canvas ? canvas.toDataURL() : EditBackground})` }}
                    >
                        <img src={Edit} alt="" />
                    </div>
                </LivePreviewUploadButtonCore>

                <Button
                    className={styles.livePreviewRemoveButton}
                    onClick={handleClearLivePreview}
                    size="xlarge"
                    design="default"
                >
                    <img src={Remove} alt="" />
                </Button>

                <Link to="/mockups">
                    <Button
                        className={styles.buttonLink}
                        size="xlarge"
                        design="black"
                    >
                        <Icon name="Image" size="sm" color="grey" />
                        <span><FormattedMessage defaultMessage="Browse all mockups" /></span>
                    </Button>
                </Link>
            </>
        );
    };

    return (
        <div className={styles.livePreviewWrapper} ref={listRef}>
            {hasPredefinedPreviews ? (
                <span className={styles.title}>
                    <FormattedMessage defaultMessage="Give it a try with our samples or upload your own image" />
                </span>
            ) : null}

            <div className={styles.buttonHolder}>
                {renderPredefinedPreviews()}

                {(canvas && !predefinedFileId || file) ? renderEditButtons() : renderUploadButton()}

                {!hasPredefinedPreviews ? (
                    <span className={styles.livePreviewDescription}>
                        <FormattedMessage defaultMessage="to get an instant preview on all mockups" />
                    </span>
                ) : null}
            </div>

            {hasPredefinedPreviews ? (
                <span className={styles.info}>
                    <FormattedMessage defaultMessage="*Your images are 100% safe, all the magic happens in your browser" />
                </span>
            ) : null}
        </div>
    );
};

LivePreviewToolbar.propTypes = {
    isClientSide: PropTypes.bool,
    hasPredefinedPreviews: PropTypes.bool,
    trigger: PropTypes.string
}

export default LivePreviewToolbar;
