import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'homepage.pageTitle',
        defaultMessage: 'Create stunning product mockups easily and online - Smartmockups'
    },
    metaDescription: {
        id: 'homepage.metaDescription',
        defaultMessage:
            "Smartmockups enables you to create stunning high-resolution mockups right inside your browser within one interface across multiple devices."
    },
    introDescription: {
        defaultMessage:
            'Forget about spending hours learning how to use Photoshop. Smartmockups enables you to create stunning high-resolution mockups right inside your browser within one interface across multiple devices.'
    },

    introList1: { defaultMessage: 'The fastest web-based mockup tool' },
    introList2: { defaultMessage: 'No experience or skills needed, super easy-to-use' },
    introList3: { defaultMessage: 'Ever-growing library of professional mockups' },

    introSubtitle: {
        defaultMessage: 'Create professional mockups right inside your browser, even on the go'
    },
    introTitle: { defaultMessage: 'Introduction' },

    featuresTitle: { defaultMessage: 'Features' },
    featuresSubtitle: { defaultMessage: 'Get the most out of each mockup with advanced features' },

    uploadTitle: { defaultMessage: 'Multiple upload options' },
    uploadDescription: {
        defaultMessage:
            'Upload your own image or video from computer, use third-party integration or grab a screenshot from URL.'
    },

    customizeTitle: { defaultMessage: 'Customize each mockup like a pro' },
    customizeDescription: {
        defaultMessage:
            'Change the background, color of the item or crop the whole scene. Our customization options are truly endless.'
    },

    exportTitle: { defaultMessage: 'Unlimited export & share' },
    exportDescription: {
        defaultMessage:
            'Once your mockup is ready, export the final image in super-high 5K resolution in few seconds with no limits.'
    },
    integrationsTitle: { defaultMessage: 'Third-party integrations' },
    integrationsSubtitle: { defaultMessage: 'Speed up your workflow' }
});

export default messages;
