import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './FeatureList.module.scss';
import MarketingIcon from '../MarketingIcon/MarketingIcon';

export default class FeatureList extends Component {
    renderIcon(item) {
        if (!item.design) {
            return <MarketingIcon width={30} height={30} name="FeatureList" />;
        }
        if (item.design === 'notAvailable') {
            return <MarketingIcon width={30} height={30} name="FeatureListNotAvailable" />;
        }
        return null;
    }
    renderItems() {
        return this.props.items.map((item, index) => {
            const contentStyle =
                item.design === 'notAvailable' ? styles.contentNotAvailable : styles.content;
            return (
                <li className={styles.item} key={index}>
                    <div className={styles.icon}>{this.renderIcon(item)}</div>
                    <span className={contentStyle}> {item.content ? item.content : item}</span>
                </li>
            );
        });
    }

    render() {
        return <ul className={styles.list}>{this.renderItems()}</ul>;
    }
}

FeatureList.propTypes = {
    items: PropTypes.array.isRequired
};
