import React, { Component } from 'react';

import styles from './HomepageHeader.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

class HeaderTitle extends Component {

    renderTitle(className) {
        return (
            <h1 className={className}>
                <FormattedMessage
                    defaultMessage="Create stunning {newLine} product mockups easily and online"
                    values={{ newLine: <br /> }}
                />
            </h1>
        );
    }

    render() {
        const { intl} = this.props;
        let titleClassName = `font-bold ${styles.title}`;

        if (intl.locale !== 'en') {
            titleClassName = `font-bold ${styles.titleSmaller}`;
        }
        if (intl.locale === 'ru') {
            titleClassName = `font-bold ${styles.titleSmallest}`;
        }
        return (
            this.renderTitle(titleClassName)
        );
    }
}

export default injectIntl(HeaderTitle);
