import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionTitle.module.scss';

import Headline from '../../Layout/Headline/Headline';

const SectionTitle = props => {
    return (
        <div className={styles.wrapper}>
            <Headline title={props.title} subtitle={props.subtitle} design={props.design} />
        </div>
    );
};

export default SectionTitle;

SectionTitle.propTypes = {
    subtitle: PropTypes.node,
    title: PropTypes.node,
    design: PropTypes.oneOf(['default', 'blue'])
};
