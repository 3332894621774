import React from 'react';

import styles from './FeatureGrid.module.scss';

const FeatureGrid = props => {
    return (
        <div className={props.singleColumn ? styles.gridSingle : styles.grid}>{props.children}</div>
    );
};

export default FeatureGrid;
