import MockupPlacehoder from '../../components/List/MockupList/MockupPlaceholder';
import React from 'react';

const ListPlaceholders = ({ count }) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
        placeholders.push(
            <MockupPlacehoder width={380} height={285} key={`mockup-placeholder-${i}`} />
        );
    }
    return placeholders;
};

export default ListPlaceholders;
