import React from 'react';
import PropTypes from 'prop-types';
import styles from './ShortDescription.module.scss';
import MarketingIcon from '../MarketingIcon/MarketingIcon';
import Icon from '../../Base/Icons/Icon';

const ShortDescription = props => {
    let titleStyles = styles.title;
    let descriptionStyles = styles.description;

    if (props.design === 'blue') {
        titleStyles = styles.titleBlue;
        descriptionStyles = styles.descriptionBlue;
    }
    if (props.design === 'disabled') {
        titleStyles = styles.titleGrey;
    }
    return (
        <div className={styles.wrapper}>
            {props.icon ? (
                <MarketingIcon name={props.icon} height={100} width={100} className={styles.icon} />
            ) : null}
            <h3 className={titleStyles}>
                {props.title}{' '}
                {props.isPro ? <Icon name="ProBadgeRight" width={50} height={20} /> : null}
            </h3>
            <p className={descriptionStyles}>{props.description}</p>
        </div>
    );
};

export default ShortDescription;

ShortDescription.propTypes = {
    description: PropTypes.node,
    title: PropTypes.node,
    icon: PropTypes.string,
    isPro: PropTypes.bool,
    design: PropTypes.oneOf(['default', 'blue', 'disabled'])
};
