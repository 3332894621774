import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Masonry.module.scss';
import { WEB_IMGIX } from '../../../utils/variables';

export default class Masonry extends Component {
    renderImage(image) {
        const imageUrl = `${WEB_IMGIX}/${image.src}`;

        return (
            <img
                key={image.src}
                className={`${image.isShadow ? styles.imageWithShadow : styles.image} lazyload`}
                src={`${imageUrl}?w=${image.width}&h=${image.height}&auto=compress&q=1&blur=200`}
                data-srcset={`${imageUrl}?w=${image.width}&h=${image.height}&dpr=2&q=60&auto=compress`}
                width={image.width}
                height={image.height}
                alt=""
                loading="lazy"
            />
        );
    }

    render() {
        const firstCol = [];
        const secondCol = [];

        // eslint-disable-next-line array-callback-return
        this.props.items.map((image, index) => {
            if (index === 0) {
                firstCol.push(this.renderImage(image));
            } else {
                secondCol.push(this.renderImage(image));
            }
        });

        return (
            <div className={styles.wrapper}>
                <div className={styles.col}>{firstCol} </div>
                <div className={styles.colMiddle}>{secondCol} </div>
            </div>
        );
    }
}

Masonry.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
            isShadow: PropTypes.bool
        })
    )
};
