import styles from './Skeleton.module.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from 'react';

const CategoriesSliderSkeleton = () => (
    <SkeletonTheme>
        <div className={styles.skeletonWrapper}>
            <div>
                <Skeleton count={6} height={'90px'} />
            </div>
            <div>
                <Skeleton height={'550px'} />
            </div>
        </div>
    </SkeletonTheme>
);

export default CategoriesSliderSkeleton;
