import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { paginateMockups } from '../../utils/mockupListFiltering';
import MockupPreview from '../../components/List/MockupPreview/MockupPreview';
import { withRouter } from 'next/router';
import { injectIntl } from 'react-intl';
import useUser from '../../hooks/useUser';
import { useInView } from 'react-intersection-observer';
import styles from '../../components/List/MockupList/MockupList.module.scss';
import Masonry from 'react-masonry-css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getCookie, setCookie } from '../../apis/helpers';
import PropTypes from 'prop-types';

const ListItems = (props) => {
    const user = useSelector((state) => state.auth.user);
    const locale = useSelector((state) => state.window.locale);
    const [isView, setIsView] = useState(getCookie('inView'));

    const { mockups, isMasonry, isNoResponsive, ratio, hasNotCollectButton } = props;
    const { query } = props.router;
    const { page } = query;
    const { isPro } = useUser();

    const { screenSM, screenXL } = useWindowDimensions();

    const margin = '700px';
    const previewInView = 20;
    const previewMaxWidth = screenXL ? 320 : 366;
    const previewMinWidth = screenXL ? 170 : 230;

    const breakpointColumnsObj = {
        default: 2
    };

    const [ref, inView] = useInView({
        rootMargin: margin,
        triggerOnce: true
    });

    useEffect(() => {
        if (inView) {
            setCookie('inView', true, 362);
            setIsView(getCookie('inView'));
        }
    }, [inView]);

    const pageCount = props.pageCount ? props.pageCount : 98;
    if (mockups.length === 0) return null;
    const paginatedMockups = paginateMockups(mockups, page, pageCount);

    const renderItem = (mockup, index) => {
        if (index < previewInView || isView) {
            return (
                <MockupPreview
                    mockup={mockup}
                    isPreviewHover
                    isUserInternal={!!(user && user.isAdmin)}
                    isUserScheduled={!!(user && user.isScheduled)}
                    isUserPremium={isPro}
                    key={mockup.id}
                    locale={locale}
                    index={index}
                    collection={props.collection || null}
                    onClick={props.onClick}
                    isUserLogged={!!user}
                    isLazyLoad={index > 1}
                    isMasonry={isMasonry}
                    hasNotCollectButton={hasNotCollectButton}
                />
            );
        }

        return null;
    };

    const renderPreview = () => {
        return paginatedMockups.map((mockup, index) => {
            const realWidth = mockup.width ? Number(mockup.width) : 3000;
            const realHeight = mockup.height ? Number(mockup.height) : 2000;
            const flexGrow = (realWidth * previewMaxWidth) / realHeight;
            const flexBasis = (realWidth * previewMinWidth) / realHeight;
            const paddingBottom = ((realHeight + 4) / realWidth) * 100;

            if (isMasonry) {
                return (
                    <div
                        key={mockup.shortId}
                        style={
                            screenSM && !isNoResponsive
                                ? null
                                : {
                                      flexBasis: `${
                                          ratio ? (flexBasis * ratio) / 100 : flexBasis
                                      }px`,
                                      flexGrow: ratio ? (flexGrow * ratio) / 100 : flexGrow,
                                      maxWidth: ratio ? (flexGrow * ratio) / 100 : flexGrow
                                  }
                        }
                        ref={index === previewInView + 1 ? ref : null}
                    >
                        <i
                            style={{
                                paddingBottom: `${paddingBottom}%`
                            }}
                        />
                        {renderItem(mockup, index)}
                    </div>
                );
            }

            return renderItem(mockup, index);
        });
    };

    if (screenSM && isMasonry && !isNoResponsive) {
        return (
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.myMasonryGrid}
                columnClassName={styles.myMasonryGridColumn}
            >
                {renderPreview()}
            </Masonry>
        );
    }

    return renderPreview();
};

ListItems.propTypes = {
    isMasonry: PropTypes.bool,
    isNoResponsive: PropTypes.bool,
    ratio: PropTypes.number,
    hasNotCollectButton: PropTypes.bool,
    onClick: PropTypes.func
};

export default injectIntl(withRouter(ListItems));
