import React from 'react';
import PropTypes from 'prop-types';
import styles from './LargeDescription.module.scss';
import FeatureList from '../FeatureList/FeatureList';
import SectionTitle from '../SectionTitle/SectionTitle';

const LargeDescription = props => {
    let titleDesign = 'default';
    let descriptionStyles = styles.description;

    if (props.design === 'blue') {
        titleDesign = 'blue';
        descriptionStyles = styles.descriptionBlue;
    }

    return (
        <div className={styles.wrapper}>
            <SectionTitle subtitle={props.subtitle} title={props.title} design={titleDesign} />
            <p className={descriptionStyles}>{props.description}</p>

            {props.list ? <FeatureList items={props.list} /> : null}
        </div>
    );
};

export default LargeDescription;

LargeDescription.propTypes = {
    description: PropTypes.node,
    list: PropTypes.array,
    subtitle: PropTypes.node,
    title: PropTypes.node,
    design: PropTypes.oneOf(['default', 'blue'])
};
