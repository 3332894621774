import React from 'react';
import styles from './MockupPlaceholder.module.scss';

const MockupPlacehoder = (props) => (
    <div className={styles.wrapper}>
        <span
            className={styles.placeholder}
            style={{ paddingTop: `${(props.height / props.width) * 100}%` }}
        />
    </div>
);

export default MockupPlacehoder;
